import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import Image from "../image"
import { lineHeight } from "@material-ui/system"
import { withAssetPrefix } from "gatsby"

type Props = {
  classes: any
}

class IndexNews extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <section className={classes.background}>
        <div
          id="news"
          className={classNames(classes.componentWrapper, "title-wrapper")}
        >
          <h1 className={classes.title}>NEWS</h1>
        </div>
        <div className={classNames(classes.itemWrapper, "mt20")}>
          <div className={classes.item}>
            <div className="mr40">2020/04/09</div>
            <div>サイトをリニューアルしました</div>
          </div>
        </div>
        {
          //<div className={classNames(classes.itemWrapper, "mt20")}>
          //  <div className={classes.item}>
          //    <div className="mr40">2019/08/01</div>
          //    <div>
          //      CEO 鈴木がFリーグ フウガドールすみだのアドバイザーに就任しました
          //  </div>
          //  </div>
          //</div>
          //<div className={classNames(classes.itemWrapper, "mt20")}>
          //  <div className={classes.item}>
          //    <div className="mr40">2019/08/01</div>
          //    <div>COZOU Inc.とクリアソンがパートナー契約を結びました</div>
          //  </div>
          //</div>
          //<div className={classNames(classes.itemWrapper, "mt20")}>
          //  <div className={classes.item}>
          //    <div className="mr40">2019/07/25</div>
          //    <div>
          //      三井不動産レジデンシャルとASjとのカヌーイベントを開催しました
          //  </div>
          //  </div>
          //</div>
          //<div className={classNames(classes.itemWrapper, "mt20")}>
          //  <div className={classes.item}>
          //    <div className="mr40">2019/07/11</div>
          //    <div>CEO 鈴木がなでケアアドバイザーに就任しました</div>
          //  </div>
          //</div>
        }
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  background: {
    paddingTop: 50,
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  title: {
    fontSize: 50,
    paddingBottom: 6,
    paddingRight: 40,
    color: "#333",
    borderBottom: "6px solid #333",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      borderBottom: "4px solid #333",
    },
  },
  componentWrapper: {
    margin: "0 auto",
    paddingTop: 90,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 50,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 30,
    },
  },
  itemWrapper: {
    border: "solid 1px #707070",
    padding: 20,
    maxWidth: 900,
  },
  item: {
    display: "flex",
    flexWrap: "wrap",
    fontSize: 15,
    fontFamily: "SFProText",
    fontWeight: "bold",
    lineHeight: 1.2,
    letterSpacing: 1.5,
  },
})

export default withStyles(styles)(IndexNews)
