import { connect } from "react-redux"
import React, { Component } from "react"
import { pure, compose } from "recompose"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Mission from "../components/index/Mission"
import Business from "../components/index/Business"
import News from "../components/index/News"
import AboutUs from "../components/index/Member"
import Company from "../components/index/Company"

import { withStyles } from "@material-ui/core/styles"

import * as masterActions from "../actions/master"

type Props = {
  requestProcess: Function
  completeProcess: Function
  isProcessing: boolean
  classes: any
}

type State = {}

class IndexPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      news: [],
      stories: [],
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="好きなことに熱中できる世界をICTの力で創出する" />
        <Mission />
        <Business />
        <News />
        <AboutUs />
        <Company />
      </Layout>
    )
  }
}

const styles: any = (theme: any) => ({
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1920,
    paddingTop: 0,
  },
})

function mapStateToProps(state: any) {
  const { isProcessing } = state.rootReducer.master

  return {
    isProcessing,
    wpModel: state.rootReducer.wordpress,
  }
}

const connector = connect(mapStateToProps, { ...masterActions })

export default compose(pure, withStyles(styles), connector)(IndexPage)
