import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import Image from "../image"
import { lineHeight } from "@material-ui/system"
import { withAssetPrefix } from "gatsby"

type Props = {
  classes: any
}

class IndexBusiness extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <section className={classes.background}>
        <div
          id="business"
          className={classNames(classes.componentWrapper, "title-wrapper")}
        >
          <h1 className={classes.title}>BUSINESS</h1>
        </div>
        <div>
          <div className="pt40">
            <div className={classes.listTitle}>開発事業</div>
            <ul className={classes.list}>
              <li>スポーツスクール顧客管理システムの開発</li>
              <li>
                <a href="https://mifa-ss.com">MIFAサッカースクールHP制作</a>
              </li>
              <li>
                <a href="http://dosa.or.jp/">一般社団法人DOSA</a>
                <ul>
                  <li>
                    <a href="http://dosa.or.jp/">HPリニューアル制作</a>
                  </li>
                  <li>
                    <a href="https://dosa.or.jp/about/works/measurement">
                      DOSAスポーツ能力測定会
                    </a>{" "}
                    ユーザー向けWebシステム開発
                  </li>
                  <li>
                    <a href="https://dosa.or.jp/about/works/dosaschool">
                      DOSA SCHOOL 学校体力テスト データ集計管理/分析システム
                    </a>{" "}
                    システム全般開発(WEB, API, Server)
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="pt20">
            <div className={classes.listTitle}>スポーツビジネス事業</div>
            <ul className={classes.list}>
              <li>
                <a href="https://mifa-ss.com">MIFAサッカースクールの運営</a>
                <ul>
                  <li>
                    <a href="https://tachikawa.mifa-ss.com/">
                      MIFAサッカースクール立川
                    </a>
                  </li>
                  <li>
                    <a href="https://fukuoka.mifa-ss.com/">
                      MIFAサッカースクール福岡
                    </a>
                  </li>
                </ul>
              </li>
              <li>スポーツオンラインサポートの実施</li>
              <li>スポーツイベント運営、実施</li>
            </ul>
          </div>
          <div className="pt20">
            <div className={classes.listTitle}>スポーツ指導者育成事業</div>
            <ul className={classes.list}>
              <li>スポーツ指導者コーチングスクール開設予定</li>
            </ul>
          </div>
          <div className="pt20">
            <div className={classes.listTitle}>飲食事業</div>
            <ul className={classes.list}>
              <li>
                <a href="https://www.instagram.com/meguro80/">
                  目黒八十（キッチンカー）
                </a>
              </li>
            </ul>
          </div>
        </div>
        {
          //<div className="pt40" id="online">
          //  <img width="240" src="/assets/images/school1.svg" className="mr40" />
          //  <img width="240" src="/assets/images/school2.svg" />
          //</div>
        }
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  background: {
    paddingTop: 50,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  title: {
    fontSize: 50,
    paddingBottom: 6,
    paddingRight: 40,
    color: "#333",
    borderBottom: "6px solid #333",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      borderBottom: "4px solid #333",
    },
  },
  componentWrapper: {
    margin: "0 auto",
    paddingTop: 90,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 50,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 30,
    },
  },
  listTitle: {
    fontSize: 20,
  },
  list: {
    paddingTop: 12,
    paddingLeft: 60,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      paddingLeft: 20,
    },
  },
})

export default withStyles(styles)(IndexBusiness)
