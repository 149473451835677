import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

type Props = {
  classes: any
}

class IndexOrganization extends Component<Props> {
  render() {
    const { classes } = this.props

    const ballIcon = (
      <svg
        className={classes.ballIcon}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 496 512"
      >
        <path d="M483.8 179.4C449.8 74.6 352.6 8 248.1 8c-25.4 0-51.2 3.9-76.7 12.2C41.2 62.5-30.1 202.4 12.2 332.6 46.2 437.4 143.4 504 247.9 504c25.4 0 51.2-3.9 76.7-12.2 130.2-42.3 201.5-182.2 159.2-312.4zm-74.5 193.7l-52.2 6.4-43.7-60.9 24.4-75.2 71.1-22.1 38.9 36.4c-.2 30.7-7.4 61.1-21.7 89.2-4.7 9.3-10.7 17.8-16.8 26.2zm0-235.4l-10.4 53.1-70.7 22-64.2-46.5V92.5l47.4-26.2c39.2 13 73.4 38 97.9 71.4zM184.9 66.4L232 92.5v73.8l-64.2 46.5-70.6-22-10.1-52.5c24.3-33.4 57.9-58.6 97.8-71.9zM139 379.5L85.9 373c-14.4-20.1-37.3-59.6-37.8-115.3l39-36.4 71.1 22.2 24.3 74.3-43.5 61.7zm48.2 67l-22.4-48.1 43.6-61.7H287l44.3 61.7-22.4 48.1c-6.2 1.8-57.6 20.4-121.7 0z" />
      </svg>
    )

    return (
      <section id="company" className={classes.root}>
        <div className={classNames(classes.componentWrapper, "title-wrapper")}>
          <h1 className={classes.title}>COMPANY</h1>
        </div>
        <div className={classes.componentWrapper}>
          <table className={classes.table}>
            <tbody>
              <tr>
                <td className={classes.columnTitle}>会社名</td>
                <td className={classes.tableSeparator}>：</td>
                <td className={classes.tableTd}>株式会社COZOU</td>
              </tr>
              <tr>
                <th className={classes.columnTitle}>代表取締役</th>
                <td className={classes.tableSeparator}>：</td>
                <td className={classes.tableTd}>鈴木良介</td>
              </tr>
              <tr>
                <th className={classes.columnTitle}>創立</th>
                <td className={classes.tableSeparator}>：</td>
                <td className={classes.tableTd}>2019年8⽉</td>
              </tr>
              <tr>
                <th className={classes.columnTitle}>資本金</th>
                <td className={classes.tableSeparator}>：</td>
                <td className={classes.tableTd}>3,000,000円</td>
              </tr>
              <tr>
                <th className={classes.columnTitle}>主な事業内容</th>
                <td className={classes.tableSeparator}>：</td>
                <td className={classes.tableTd}>
                  開発事業　スポーツ事業　教育事業
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  title: {
    fontSize: 50,
    paddingBottom: 6,
    paddingRight: 40,
    color: "#333",
    borderBottom: "6px solid #333",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      borderBottom: "4px solid #333",
    },
  },
  componentWrapper: {
    paddingTop: 50,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 30,
    },
  },
  ballIcon: {
    height: 40,
    width: 40,
    fill: "white",
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
    [theme.breakpoints.down("xs")]: {
      height: 20,
    },
  },
  columnTitle: {
    border: "none",
    fontWeight: "normal",
    whiteSpace: "nowrap",
    width: "10%",
    padding: 0,
    paddingBottom: 12,
  },
  tableTd: {
    border: "none",
    width: "89%",
    padding: 0,
    paddingBottom: 12,
  },
  tableSeparator: {
    width: "1%",
    border: "none",
    padding: 0,
    paddingBottom: 12,
    paddingLeft: 12,
    paddingRight: 12,
  },
})

export default withStyles(styles)(IndexOrganization)
